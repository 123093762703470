import { Injectable } from '@angular/core';
import {BrandService} from './brand.service';

@Injectable({
  providedIn: 'root'
})
export class LiveChatService {

  livechatLicense = null;

  livechatDealers = {};

  // Livechat - disabled 18.10.2021 - FCASTOCK3-524
  // livechatLicense = 7128661;
  //
  // livechatDealers = {
  //   '0075206': {
  //     'fiat': 4,
  //     'abarht': 14,
  //     'alfa-romeo': 16
  //   },
  //   '0720006': {
  //     'jeep': 15
  //   },
  //   '0075296': {
  //     'fiat': 6,
  //     'abarth': 6
  //   },
  //   '0720019': {
  //     'jeep': 12
  //   },
  //   '0712341': {
  //     'jeep': 11
  //   },
  //   '0712335': {
  //     'jeep': 17
  //   }
  // };

  constructor() { }

  getDealerLiveChatGroup(dealerCode) {
    if (this.livechatDealers[dealerCode]) {
      if (this.livechatDealers[dealerCode][BrandService.getCurrentBrand().slug]) {
        return this.livechatDealers[dealerCode][BrandService.getCurrentBrand().slug];
      }
    }
    return false;
  }
}
