import { Injectable } from '@angular/core';
import {BrandService} from './brand.service';
import {AppComponent} from '../app.component';
import {TrackingService} from './tracking.service';
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class TawkToChatService {

  private static config = {
    '0075416': {
      'fiat': {propertyId: '560a8f7c9cffede15f1fa90a', widgetId: '1i6hbcqrn'},
      'fiat-professional': {propertyId: '561271e80de0759b4ed32979', widgetId: '1i6hc3kdm'},
    },
    '0720016': {
      'jeep': {propertyId: '560a8f7c9cffede15f1fa90a', widgetId: '1i6hc0fi4'}
    }
  }

  constructor() { }

  public static enableTawkToChat(dealerCode, isOfferView) {
    if (TrackingService.checkCookie('opncl_comfort') === 'true'
      && TawkToChatService.config[dealerCode]
      && TawkToChatService.config[dealerCode][BrandService.getCurrentBrand().slug]
      && (isOfferView || AppComponent.isDealerStock))
    {
      let config = TawkToChatService.config[dealerCode][BrandService.getCurrentBrand().slug];
      if (window.Tawk_API === undefined) {
        const newScript = document.createElement('script');
        newScript.text = 'var Tawk_API={}, Tawk_LoadStart=new Date();\n' +
          '(function(){\n' +
          'var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];\n' +
          's1.async=true;\n' +
          's1.src=\'https://embed.tawk.to/' + config.propertyId + '/' + config.widgetId + '\';\n' +
          's1.charset=\'UTF-8\';\n' +
          's1.setAttribute(\'crossorigin\',\'*\');\n' +
          's0.parentNode.insertBefore(s1,s0);\n' +
          '})();';

        newScript.id = 'TawkScript';
        document.body.appendChild(newScript);
      } else {
        window.Tawk_API.switchWidget(config);
      }
    }
  }
  public static disableTawkToChat() {
    if (window.Tawk_API && !AppComponent.isDealerStock) {
      window.Tawk_API.shutdown();

      var tawkScript = document.querySelector('script[src*="tawk.to"]');
      if (tawkScript) {
        tawkScript.parentNode.removeChild(tawkScript);
      }
    }
  }
}
